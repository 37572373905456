import {ohNo} from '@/modules/oh_no';
import {Store} from '@/modules/stores';
import BabbleID from '@/modules/babble_id';

import {newTooltip} from './model';
import {findForAnchorElement} from './index';

// create a predefined tooltips container, for performance
// https://web.archive.org/web/20210827084020/https://atfzl.com/don-t-attach-tooltips-to-document-body
export let tooltipsContainer;
export const tooltipInstances = new Map();
// when scroll or resize or maybe even dom inserts/updates happen, we may have
// to move the tooltips around.
export const placementBuster = new Store(0);

// Please read caveats in README
export const SHOW_EVENTS = [`mouseenter`, `focus`];
export const HIDE_EVENTS = [`mouseleave`, `blur`];

export function prepareTooltipsContainer() {
  tooltipsContainer = document.createElement(`div`);
  tooltipsContainer.classList.add(`tooltips-container`);

  document.body.insertAdjacentElement(`beforeend`, tooltipsContainer);
}

export function watchForPlacementInvalidations() {
  window.addEventListener(`scroll`, () => {
    placementBuster.update(n => n + 1);
  });
  window.addEventListener(`resize`, () => {
    placementBuster.update(n => n + 1);
  });
}

export function watchForNewTooltips() {
  const config = {childList: true, subtree: true};
  const observer = new MutationObserver(watchForNewTooltipsObserver);
  observer.observe(document.body, config);
}

function watchForNewTooltipsObserver(mutations) {
  for (const mutation of mutations) {
    switch (mutation.type) {
      // This case happens when elements are inserted or removed from anywhere
      // inside the body, even when text is changed (as they are text nodes).
      case `childList`:
        // This will invalidate all tooltips positions when the dom is modified.
        // sadly this means that when new tooltips are inserted, all existing
        // ones must recalculate position. The upside is that we don't need to
        // worry about tooltip positioning being stale if we have other
        // javascript which shifts the layout around.
        placementBuster.update(n => n + 1);

        const baseElement = mutation.target;
        if (baseElement) {
          const anchorsWithId = baseElement.querySelectorAll(`[data-tooltipped-by]`);
          checkForSameTooltippedBy(anchorsWithId);
          const anchorElements = [
            ...baseElement.querySelectorAll(`[data-tooltip]`),
            ...anchorsWithId,
          ];
          if (anchorElements.length) {
            anchorElements.forEach(anchor => {
              hydrateTooltipAnchor(anchor);
            });
          }
        }
        break;

      default:
        break;
    }
  }
}

import {uniq} from 'lodash';
let duplicateDataTooltippedByTriggered = false;

function checkForSameTooltippedBy(elements) {
  // because of the mutationObserver, this function will be called in an endless
  // loop if it triggers (in development).
  if (duplicateDataTooltippedByTriggered) return;

  const ids = Array
    .from(elements)
    .map(el => el.dataset.tooltippedBy);

  const uniqIds = uniq(ids);

  if (uniqIds.length !== ids.length) {
    duplicateDataTooltippedByTriggered = true;
    ohNo(
      `tooltips.js: There are duplicate tooltip ids referenced by anchor` +
      ` elements! (data-tooltipped-by is the same for one or more elements)`,
    );
  }
}

export function hydrateAnchorElements() {
  const anchorsWithId = document.querySelectorAll(`[data-tooltipped-by]`);
  checkForSameTooltippedBy(anchorsWithId);
  const anchorElements = [
    ...document.querySelectorAll(`[data-tooltip]`),
    ...anchorsWithId,
  ];
  anchorElements.forEach(anchor => {
    hydrateTooltipAnchor(anchor);
  });
}

export function hydrateTooltipAnchor(anchor) {
  if (anchor.dataset.tooltipInitialized) {
    // console.debug(
    //   anchor,
    //   `is already initialized but hydrateReferenceElement() was called on it anyway`,
    // );
    return;
  }

  const tooltip = initializeTooltipFor(anchor);

  SHOW_EVENTS.forEach(event => {
    // Please read caveats in README
    anchor.addEventListener(event, tooltip.show);
  });

  HIDE_EVENTS.forEach(event => {
    anchor.addEventListener(event, tooltip.hide);
  });

  anchor.dataset.tooltipInitialized = true;
}

function tooltipReferenceAttributesObserver(mutations) {
  for (const mutation of mutations) {
    switch (mutation.type) {
      case `attributes`:
        const tooltip = findForAnchorElement(mutation.target);
        if (tooltip) {
          tooltip.setContent(mutation.target.dataset.tooltip);
        }
        break;

      default:
        break;
    }
  }
}

function initializeTooltipFor(anchor) {
  const tooltipOptions = parseTooltipOptionsFromAnchor(anchor);

  const instance = newTooltip(tooltipOptions);

  if (instance.inline) {
    instance.setContent(anchor.dataset.tooltip);

    const config = {attributes: true, attributeFilter: [`data-tooltip`]};
    const observer = new MutationObserver(tooltipReferenceAttributesObserver);
    observer.observe(instance.anchor, config);
  } else {
    const preRenderedTooltip = findPrerenderedTooltip(instance);
    if (preRenderedTooltip) {
      instance.commandeerPrerenderedTooltip(preRenderedTooltip);
    }
  }

  if (tooltipOptions.indicator) {
    anchor.classList.add(`tooltip_indicator`);
  }

  tooltipInstances.set(instance.id, instance);
  return instance;
}

function findPrerenderedTooltip(instance) {
  const candidates = document.querySelectorAll(`[data-tooltip-id=${instance.id}]`);

  if (candidates.length === 0) {
    ohNo(
      `failed to find tooltip element with data-tooltip-id="${instance.id}",` +
      ` make sure it is rendered before the anchor`,
    );
    return null;
  } else if (candidates.length === 1) {
    return candidates[0];
  } else {
    const msg = `there are multiple elements with data-tooltip-id="${instance.id}"`;
    ohNo(msg);
    return null;
  }
}

function parseTooltipOptionsFromAnchor(anchor) {
  return {
    anchor,
    placement: parsePlacement(anchor.dataset.tooltipPlacement),
    indicator: anchor.dataset.tooltipIndicator === `false` ? false : true,
    ...parseId(anchor),
    delayProfile: `fast`,
  };
}

function parsePlacement(placement) {
  if (!placement) return `right`; // default

  if (![`top`, `bottom`, `left`, `right`].includes(placement)) {
    ohNo(`invalid placement string: ${placement}`);
    return `right`;
  }

  return placement;
}

function parseId(anchor) {
  // in this context, the user is the developer, setting an id in the dom.
  const userSpecifiedID = anchor.dataset.tooltippedBy;

  // an inline instance is rendered into the data-tooltip attribute on the
  // anchor element, while a non-inline one is rendered separately in the
  // dom before being hydrated by this script.
  if (userSpecifiedID) {
    return {
      id: userSpecifiedID,
      inline: false,
    };
  } else {
    // if the user didn't render the tooltip themselves, we need to generate an
    // id for the hashmap.
    const id = generateDomID();
    anchor.dataset.tooltippedBy = id;

    return {
      id,
      inline: true,
    };
  }
}

function generateDomID() {
  // namespace ids so that you don't collide with them when using
  // business-object words (such as payment, or invoice) in ids.
  return `tt_${BabbleID()}`;
}
